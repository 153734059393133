import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Bottom Bar Component displays three to five destinations at the bottom of the screen. Each destination is represented by an icon and an optional text label. When the bottom navigation icon is clicked, the user is taken to the corresponding top-level navigation destination associated with that icon.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "width": 250,
            "height": 500,
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/bottombar/android_bottombar.gif",
            "alt": "Legion Bottombar Android"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <h2>{`Usage`}</h2>
    <p>{`To implement bottombar, you can follow the steps below.`}</p>
    <h3>{`Basic Bottom Bar`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.bottombar.LgnBottomBar  
    android:layout_width="match_parent"  
    android:layout_height="wrap_content"  
    android:layout_marginTop="?attr/spacing2"  
    app:labelVisibilityMode="labeled"  
    app:menu="@menu/bottombar_menu"/>

`}</code></pre>
    <h3>{`Bottom bar with Middle button`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.bottombar.LgnBottomBar  
    android:layout_width="match_parent"  
    android:layout_height="wrap_content"  
    android:layout_marginTop="?attr/spacing2"  
    app:labelVisibilityMode="labeled"  
    app:menu="@menu/bottombar_menu"  
    app:showMiddleButton="true"  
    app:middleButtonIconDrawable="@drawable/ic_camera"/>


`}</code></pre>
    <h3>{`Kotlin Code`}</h3>
    <h4>{`Setup with navController`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.apply {
   ...
   bottomBarView.setupWithNavController(navController)
   ...
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <h4>{`Setup with Selected Item Listener`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.apply {
   ...
   bottomBarView.setOnItemSelectedListener { menuItem ->
       when (menuItem.itemId) {
           ...
           R.id.home -> {
              ...
              true
           }
           else -> {
              ...
              true
           }
       }
   }
   ...
}
 
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <h4>{`Setup middle button`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.apply {
   ...
   bottomBarView.setOnMiddleButtonClickListener {
       ...
   }
   ...
}
 
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Menu`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:menu`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.menu`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set menu item on Bottom bar`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`label visibility mode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:labelVisibilityMode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.labelVisibilityMode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set label visibility on menu item in Bottom bar`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Show Middle Button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:showMiddleButton`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.showMiddleButton`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set visibility on middle button on Bottom bar`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Middle Button Icon Drawable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:middleButtonIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.middleButtonIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set icon drawable for middle button on Bottom bar`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Get or Create Badge`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`getOrCreateBadge`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create or Get existing badge on Menu in Bottom bar`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Remove Badge`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`removeBadge`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Remove existing badge on Menu in Bottom bar`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Setup Bottom bar With NavController`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setupWithNavController`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set up Bottom bar with NavController`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Listener for selected menu item`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SetOnItemSelectedListener`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set up listener for selected menu item on Bottom bar`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Listener for reselected menu item`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SetOnItemReselectedListener`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set up listener for reselected menu item on Bottom bar`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Listener for middle button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setOnMiddleButtonClickListener`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set up listener for middle button on Bottom bar`}</td>
        </tr>
      </tbody>
    </table>
    <div className="divi" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      